import React from 'react';
import FoodOrderOverview from "./foodOrderOverview";
import Navigation from "../Navigation/mainNavigation";
import SubNavigation from "../Navigation/subNavigation";

const FoodOrderPage = () => {
    return (
        <div className="app">
            <Navigation />
            <SubNavigation />
            <FoodOrderOverview />
        </div>
    );
}

export default FoodOrderPage;