import React, {useEffect, useState} from "react";
import {withFirebase} from "../Firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import RestaurantList from "../Restaurant/restaurantList";
import RestaurantModal from "./restaurantModal";

const RestaurantOverviewPage = ({firebase}) => {
    const [restaurants, setRestaurants] = useState([]);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // async function test () {
        firebase.auth.onAuthStateChanged(
            authUser => {
                if (authUser) {
                    firebase.getUserCollection()
                        .doc(authUser.uid)
                        .get()
                        .then((userDoc) => {
                            setUser(userDoc.data());
                        })
                        .then(() => {
                            firebase.getRestaurantCollection()
                                .where("id_owner", "==", authUser.uid)
                                .get()
                                .then((querySnapShot) => {
                                    let restaurantDocs = [];
                                    querySnapShot.forEach((restaurantDoc) => {
                                        if (restaurantDoc.data().active) {
                                            restaurantDocs.push(restaurantDoc.data());
                                        }
                                    });
                                    setRestaurants(restaurantDocs);
                                })
                                .then(() => {
                                    setIsLoading(false);
                                })
                                .catch(function (error) {
                                    console.log("Error getting documents: ", error);
                                });
                        });
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="app">
            <div className="section-container">
                <h1 className="h3-styles">Restaurants</h1>
                <RestaurantModal
                    firebase={firebase}
                    owner={user}
                />
                { isLoading ? (
                    <CircularProgress />
                ) : (
                    restaurants.length > 0 ? (
                        <RestaurantList
                            restaurants={restaurants}
                        />
                    ) : (
                        <div className="gap-top">Keine Einträge gefunden.</div>
                    )
                )}
            </div>
        </div>
    )
};

export default withFirebase(RestaurantOverviewPage);
