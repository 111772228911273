import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import uuid from "uuid/v4";
import {withFirebase} from "../Firebase";
import {useParams} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import '../../styles/registration-page.scss';
import {HomeOutlined, PersonOutlineOutlined, PhoneOutlined, QueryBuilderOutlined} from "@material-ui/icons";
import {checkmarkImage, logoImage, restaurantImage} from "../../assets/images";
import {TimePicker} from "@material-ui/pickers";

const RegistrationPage = ({ firebase }) => {
    let currentTime = new Date();
    const restaurantSlug = useParams()[0];
    const [error, setError] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(currentTime.setHours(currentTime.getHours() + 1));
    const [affirmation, setAffirmation] = useState(false);
    const [accept, setAccept] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [restaurant, setRestaurant] = useState(null);
    const [registered, setRegistered] = useState(false);
    const isInvalid = (firstName === '' || lastName === '' || street === '' || streetNumber === '');

    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        setIsLoading(true);

        firebase
            .getRestaurantCollection()
            .where("slug", "==", restaurantSlug)
            .onSnapshot(collection => {
                if (isMounted) {
                    let restaurantList = collection.docs.map(function (snapshot) {
                        return snapshot.data();
                    });
                    let currentRestaurant = restaurantList[0];
                    setRestaurant(currentRestaurant);
                    setError(null);
                    setIsLoading(false);
                }
            });
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = event => {
        event.preventDefault();

        let registration = {
            id: uuid(),
            id_restaurant: restaurant.id,
            firstName: firstName,
            lastName: lastName,
            street: street,
            streetNumber: streetNumber,
            zip: zip,
            city: city,
            phone: phone,
            email: email,
            startTime: startTime,
            endTime: endTime,
            affirmation: affirmation,
            accept: accept,
            createdAt: new Date(),
        };

        firebase
            .getRegistrationCollection()
            .doc(registration.id.toString())
            .set(registration)
            .then(() => {
                setFirstName('');
                setLastName('');
                setStreet('');
                setStreetNumber('');
                setZip('');
                setCity('');
                setPhone('');
                setEmail('');
                setStartTime(new Date());
                setEndTime(currentTime);
                setAffirmation(false);
                setAccept(false);
                setRegistered(true);
            })
            .catch(() => setError('Beim Anlegen des Restaurants ist etwas schief gegangen.'));
    };

    const acceptLabel = (
        <span>Ich akzeptiere die&nbsp;
            <a
                className="inline-link"
                href="https://www.test.de"
                target="_blank"
                rel="noopener noreferrer"
            >
                Datenschutzerklärung
            </a>
        </span>
    );

    const registrationForm = () => {
        return (
            <>
                <h1 className="h4-styles">Registrierung für Gäste</h1>
                <p>Zu Ihrer Sicherheit bitten wir Sie sich mit Ihren persönlichen Daten zu registrieren.</p>
                {error && <div className="button error-message">{error}</div>}
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <HomeOutlined color="primary" className="section-icon" />
                        <h2 className="h4-styles">Adresse</h2>
                        <div className="field-padding">
                            <TextField
                                name="firstName"
                                label="Vorname"
                                value={firstName}
                                onChange={(event => setFirstName(event.target.value))}
                                type="text"
                                variant="outlined"
                                required={true}
                                autoFocus={true}
                            />
                        </div>
                        <div className="field-padding">
                            <TextField
                                name="lastName"
                                label="Nachname"
                                value={lastName}
                                onChange={(event => setLastName(event.target.value))}
                                type="text"
                                variant="outlined"
                                required={true}
                            />
                        </div>
                        <div className="field-padding">
                            <TextField
                                name="street"
                                label="Straße"
                                value={street}
                                onChange={(event => setStreet(event.target.value))}
                                type="text"
                                variant="outlined"
                                required={true}
                            />
                        </div>
                        <div className="field-padding">
                            <TextField
                                name="streetNumber"
                                label="Hausnummer"
                                value={streetNumber}
                                onChange={(event => setStreetNumber(event.target.value))}
                                type="text"
                                variant="outlined"
                                required={true}
                            />
                        </div>
                        <div className="field-padding">
                            <TextField
                                name="zip"
                                label="Postleitzahl"
                                value={zip}
                                onChange={(event => setZip(event.target.value))}
                                type="text"
                                variant="outlined"
                                required={true}
                            />
                        </div>
                        <div className="field-padding">
                            <TextField
                                name="city"
                                label="Stadt"
                                value={city}
                                onChange={(event => setCity(event.target.value))}
                                type="text"
                                variant="outlined"
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <PhoneOutlined color="primary" className="section-icon" />
                        <h2 className="h4-styles">Kontakt</h2>
                        <div className="field-padding">
                            <TextField
                                name="phone"
                                label="Telefonnummer"
                                value={phone}
                                onChange={(event => setPhone(event.target.value))}
                                type="text"
                                variant="outlined"
                                required={true}
                            />
                        </div>
                        <div className="field-padding">
                            <TextField
                                name="email"
                                label="E-Mail"
                                value={email}
                                onChange={(event => setEmail(event.target.value))}
                                type="text"
                                variant="outlined"
                                required={true}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <QueryBuilderOutlined color="primary" className="section-icon" />
                        <h2 className="h4-styles">Kontakt</h2>
                        <div className="field-padding">
                            <TimePicker
                                label="Beginn"
                                placeholder="08:00 AM"
                                mask="__:__"
                                required={true}
                                value={startTime}
                                ampm={false}
                                onChange={setStartTime}
                                InputProps={{
                                    disableUnderline: true
                                }}
                            />
                        </div>
                        <div className="field-padding">
                            <TimePicker
                                label="Ende"
                                placeholder="08:00 AM"
                                mask="__:__"
                                required={true}
                                value={endTime}
                                ampm={false}
                                onChange={setEndTime}
                                InputProps={{
                                    disableUnderline: true
                                }}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <PersonOutlineOutlined color="primary" className="section-icon" />
                        <h2 className="h4-styles">Einwilligungen</h2>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={affirmation}
                                    onClick={(event => setAffirmation(event.target.checked))}
                                    name="affirmation"
                                    color="primary"
                                    required={true}
                                />
                            }
                            label={<span style={{ fontSize: '12px' }}>{"Ich versichere die Richtigkeit meiner Daten"}</span>}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={accept}
                                    onClick={(event => setAccept(event.target.checked))}
                                    name="accept"
                                    color="primary"
                                    required={true}
                                />
                            }
                            label={<span style={{ fontSize: '12px' }}>{acceptLabel}</span>}
                        />
                    </div>

                    <div className="form-group">
                        <div className="button-group">
                            <button
                                className="button button-primary full-width"
                                type="submit"
                                disabled={isInvalid}
                            >
                                Registrieren
                            </button>
                        </div>
                    </div>
                </form>
            </>
        )
    }

    const confirmationScreen = () => {
        return (
            <>
                <img src={checkmarkImage} alt="Grünes Bestätigung Bild" className="confirmation-image" />
                <h1 className="h4-styles">Registrierung erfolgreich. Vielen Dank!</h1>
                <button type="button" className="button button-secondary full-width" onClick={() => setRegistered(false)}>Weitere Person registrieren</button>
                <div className="separator"></div>
                <div>Ihnen gefällt unser Service?</div>
                <h2 className="h4-styles">Bewerten Sie uns auf Google</h2>
                <a href="https://maps.google.de" target="_blank" rel="noopener noreferrer" className="button button-primary full-width">Rezession schreiben</a>
            </>
        )
    }

    return (
        <div className="registration-page">
            { isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    <div className="registration-header">
                        <div className="registration-navigation container">
                            <div className="link-logo">
                                <img src={restaurantImage} alt="restaurant logo" className="logo" />
                            </div>
                            <div className="navigation-headline">
                                <div className="restaurant-name">{restaurant && restaurant.name}</div>
                                <div className="branding">Powered by meal-me</div>
                            </div>
                            <div className="right-menu">
                                <div className="link-logo">
                                    <img src={logoImage} alt="restaurant logo" className="logo" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="container">
                        {registered
                            ? confirmationScreen()
                            : registrationForm()
                        }
                    </div>
                </>
            )}
        </div>
    )
}

export default withFirebase(RegistrationPage);