import React, { useState, useEffect } from 'react';
import { withFirebase } from "../Firebase";

import OrderModal from "../OrderItem/orderModal";
import OrderList from "../OrderItem/orderList";
import CircularProgress from "@material-ui/core/CircularProgress";
import FoodOrderModal from "./foodOrderModal";
import FoodOrderSettings from "./foodOrderSettings";

const FoodOrderOverview = ({ firebase }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [foodOrder, setFoodOrder] = useState(null);
    const [currentFoodOrder, setCurrentFoodOrder] = useState(null);
    const [error, setError] = useState(null);
    const [active, setActive] = useState(false);

    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        setIsLoading(true);
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        firebase
            .getFoodOrderCollection()
            .orderBy("createdAt", "desc")
            .where("date", "==", today)
            .onSnapshot(collection => {
                if (isMounted) {
                    let foodOrderList = collection.docs.map(function (snapshot) {
                        return snapshot.data();
                    });
                    let currently = foodOrderList[0];
                    setFoodOrder(foodOrderList);
                    setCurrentFoodOrder(currently)
                    setActive(currently && currently.active && new Date(currently.deadline.seconds * 1000) >= new Date());
                    setError(null);
                    setIsLoading(false);
                }
            });
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCurrentFoodOrder]);

    const onRemoveItem = (orderItem) => {
        let foodOrderOfItem = foodOrder.filter(function (foodOrder) {
            return foodOrder.id = orderItem.foodOrder_id;
        })[0];

        foodOrderOfItem.orderItems = foodOrderOfItem.orderItems.filter(function (item) {
            return item.id !== orderItem.id;
        });

        firebase
            .getFoodOrderCollection()
            .doc(foodOrderOfItem.id)
            .update(foodOrderOfItem)
            .then(() => setError(null))
            .catch(() => setError('removal failed'));
    };

    const onStopOrder = () => {
        currentFoodOrder.active = false;
        currentFoodOrder.deadline = new Date();

        firebase
            .getFoodOrderCollection()
            .doc(currentFoodOrder.id)
            .update(currentFoodOrder)
            .then(() => setError(null))
            .catch(() => setError('removal failed'));
    }

    return (
        <>
            { isLoading ? (
                <div className="container-full">
                    <CircularProgress />
                </div>
            ) : (
                foodOrder && foodOrder.length > 0 ? (
                    <div className="container-full">
                        { error && <div className="button error-message">{error.message}</div>}
                        <div className="container">
                            {foodOrder ? (
                                <FoodOrderSettings
                                   firebase={firebase}
                                   foodOrder={currentFoodOrder}
                                   foodOrderList={foodOrder}
                                   onStopOrder={onStopOrder}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="container">
                            {currentFoodOrder ? (
                                <>
                                    {active ? (
                                    <OrderModal
                                        firebase={firebase}
                                        foodOrder={currentFoodOrder}
                                    />
                                    ) : '' }

                                    <OrderList
                                        foodOrder={currentFoodOrder}
                                        onRemoveItem={onRemoveItem}
                                        collapse={false}
                                        index={0}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="container-full center">
                        <FoodOrderModal
                            firebase={firebase}
                            type={"new"}
                        />
                    </div>
                )
            )}
        </>
    );
};

export default withFirebase(FoodOrderOverview);