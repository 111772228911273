import React from 'react';
import RestaurantIcon from '@material-ui/icons/Restaurant';

const RestaurantItem = ({restaurant}) => {
    return (
        <div className="restaurant-item">
            <div className="restaurant-image"><RestaurantIcon /></div>
            <div className="restaurant-name">{restaurant.name}</div>
            <div className="restaurant-address">{restaurant.street} {restaurant.streetNumber}<br /> {restaurant.zip} {restaurant.city}</div>
        </div>
    )
};

export default RestaurantItem;