import React, {useEffect, useState} from 'react';
import logo from "../../assets/images/logo.png";
import { Link as AnchorLink, Element, animateScroll as scroll, scrollSpy } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Popover from "@material-ui/core/Popover";
import { registrationImage, guestsImage, mockupImage, qrImage, ratingImage, safetyImage, securityImage, patreonWordImage } from '../../assets/images/landing';
import { makeStyles } from '@material-ui/core/styles';
import logoFont from '../../assets/images/logo_font.png';
import './landing.scss';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {SignInPopover} from "../Auth/signIn";
import SignUpForm from "../Company/signUp";
import {CloseOutlined} from "@material-ui/icons";

const LandingPage = () => {
    const [formType, setFormType] = useState(null);
    const [openNavigation, setOpenNavigation] = useState(false);
    const open = Boolean(formType);
    const classes = useStyles();
    let navbar = document.getElementById("root");

    useEffect(() => {
        scrollSpy.update();
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleMobileNavigation = () => {
        setOpenNavigation(!openNavigation);
    }

    const changePopoverType = (type) => {
        setFormType(type);
    }

    const OpenForm = () => {
        let form = formType === 'login'
            ? <SignInPopover changePopoverType={changePopoverType} />
            : <SignUpForm changePopoverType={changePopoverType} />;

        return (
            <div className="login-popover">
                <CloseOutlined className="close-button" onClick={() => setFormType(null)} />
                { form }
            </div>
        )
    }

    return (
        <div className="app landing">
            <nav className="main-navigation" id="main-navigation">
                <div className="section-container">
                    <div onClick={() => scroll.scrollToTop()} className="link-logo">
                        <img src={logo} alt="logo" className="logo" />
                    </div>
                    <AnchorLink className="button button-secondary color-dark hidden-on-mobile" activeClass="active" to="home" spy={true} smooth={true} duration={500} offset={-64}>
                        Home
                    </AnchorLink>
                    <AnchorLink className="button button-secondary color-dark hidden-on-mobile" activeClass="active" to="function" spy={true} smooth={true} duration={500} offset={-64}>
                        So funktioniert's
                    </AnchorLink>
                    <AnchorLink className="button button-secondary color-dark hidden-on-mobile" activeClass="active" to="security" spy={true} smooth={true} duration={500} offset={-64}>
                        Sicherheit
                    </AnchorLink>
                    <AnchorLink className="button button-secondary color-dark hidden-on-mobile" activeClass="active" to="patreon" spy={true} smooth={true} duration={500} offset={-64}>
                        Unterstützen
                    </AnchorLink>
                    <div className="right-menu">
                        <div onClick={() => setFormType('login')} className="button button-secondary hidden-on-mobile">Anmelden</div>
                        <div onClick={() => setFormType('register')} className="button button-primary hidden-on-mobile">Kostenlos starten</div>
                        <div onClick={() => toggleMobileNavigation()} className={`hamburger-button ${openNavigation ? "open" : ""}`}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </nav>

            <ul className={`mobile-main-navigation ${openNavigation ? "open" : ""}`}>
                <li>
                    <AnchorLink className="button button-secondary full-width color-dark" activeClass="active" to="home" spy={true} smooth={true} duration={500} offset={-64}>
                        Home
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink className="button button-secondary full-width color-dark" activeClass="active" to="function" spy={true} smooth={true} duration={500} offset={-64}>
                        So funktioniert's
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink className="button button-secondary full-width color-dark" activeClass="active" to="security" spy={true} smooth={true} duration={500} offset={-64}>
                        Sicherheit
                    </AnchorLink>
                </li>
                <li>
                    <AnchorLink className="button button-secondary full-width color-dark" activeClass="active" to="patreon" spy={true} smooth={true} duration={500} offset={-64}>
                        Unterstützen
                    </AnchorLink>
                </li>
                <li>
                    <div onClick={() => setFormType('login')} className="button button-secondary full-width">Anmelden</div>
                </li>
                <li>
                    <div onClick={() => setFormType('register')} className="button button-primary full-width">Kostenlos starten</div>
                </li>
            </ul>

            <Popover
                id={'simple-popover'}
                open={open}
                anchorEl={navbar}
                onClose={() => setFormType(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
            >
                {<OpenForm />}
            </Popover>

            <Element name="home" className="section section-inverted">
                <div className="section-container">
                    <div className="widget-container no-flex widget-reverse">
                        <div className="widget widget-padding-right">
                            <h1 className="h1-styles">Sichere Online- Registrierung für Gäste in Ihrem Restaurant</h1>
                            <p>Mit meal-me halten Sie alle Auflagen in der Covid-19 Pandemie problemlos ein. Gäste in Ihrem Restaurant registrieren sich kontaktlos direkt über unsere Website.</p>
                            <div onClick={() => setFormType('register')} className="button button-primary">
                                Kostenlos starten
                            </div>
                        </div>
                        <div className="widget widget-absolute">
                            <img src={mockupImage} className="image-widget image-big" alt="Restaurant" />
                        </div>
                    </div>
                </div>
            </Element>

            <Element name="function" className="section">
                <div className="section-container">
                    <h2 className="h2-styles align-center">So funktioniert's</h2>

                    <div className="widget-container widget-reverse">
                        <div className="widget widget-text">
                            <h3 className="h3-styles">Restaurant registrieren</h3>
                            <p>Erstellen Sie ein Benutzkonto für Ihr Restaurant. Hier können Sie alle Registrierungen Ihrer Kunden einsehen und verwalten.</p>
                        </div>
                        <div className="widget widget-image">
                            <img src={registrationImage} className="image-widget" alt="Restaurant" />
                        </div>
                    </div>

                    <div className="widget-container">
                        <div className="widget widget-image">
                            <img src={qrImage} className="image-widget" alt="Restaurant" />
                        </div>
                        <div className="widget widget-text">
                            <h3 className="h3-styles">QR Code für Ihr Restaurant downloaden oder als Sticker bestellen</h3>
                            <p>Nach Ihrer Registrierung erhalten Sie einen eigenen Link für Ihr Restaurant, über den sich Ihre Gäste registrieren können. Hierzu können Sie sich ein Druck-PDF herunterladen oder eine Online-Druckerei beauftragen.</p>
                        </div>
                    </div>

                    <div className="widget-container widget-reverse">
                        <div className="widget widget-text">
                            <h3 className="h3-styles">Gäste registrieren sich direkt am Tisch über ihr eigenes Smartphone</h3>
                            <p>Ihre Gäste scannen den QR-Code Ihres Restaurants und werden direkt auf das DSGVO-konforme Registrierungsformular weitergeleitet.</p>
                        </div>
                        <div className="widget widget-image">
                            <img src={guestsImage} className="image-widget" alt="QR Code" />
                        </div>
                    </div>

                    <div className="widget-container">
                        <div className="widget widget-image">
                            <img src={securityImage} className="image-widget" alt="Security" />
                        </div>
                        <div className="widget widget-text">
                            <h3 className="h3-styles">Sie sind sicher bei jeder Prüfung und Kontrolle</h3>
                            <p>Bei Kontrollen können jederzeit alle gesammelten Datensätze als Datei exportieren. So sind Sie immer sicher vor Polizei und Ordnungsamt.</p>
                        </div>
                    </div>

                    <div className="widget-container widget-reverse">
                        <div className="widget widget-text">
                            <h3 className="h3-styles">Steigern Sie Ihre Google-Bewertungen</h3>
                            <p>Wir bitten jeden Gast nach der Registrierung höflich, Ihr Restaurant auf Google zu bewerten. Dadurch verbessern Sie ihre Auffindbarkeit.</p>
                        </div>
                        <div className="widget widget-image">
                            <img src={ratingImage} className="image-widget" alt="Rating" />
                        </div>
                    </div>
                </div>
            </Element>

            <Element name="security" className="section section-inverted">
                <div className="section-container">
                    <h2 className="h2-styles align-center">Ihre Daten und die Daten Ihrer Kunden sind bei uns jederzeit sicher</h2>

                    <div className="widget-container">
                        <div className="widget widget-image">
                            <img src={safetyImage} className="image-widget" alt="Safety" />
                        </div>
                        <div className="widget widget-text">
                            <ul className="icon-list">
                                <li>
                                    <CheckCircleIcon />
                                    <div className="h4-styles">Datenschutzkonform dank Double Opt-in-Verfahren</div>
                                    <div>Jeder Gast muss die vom Fachanwalt für IT-Recht geprüfte Datenschutzrichtlinien und die AGB von meal-me vor der Registrierung bestätigen.</div>
                                </li>
                                <li>
                                    <CheckCircleIcon />
                                    <div className="h4-styles">Alle Daten sind auf deutschen Servern gespeichert</div>
                                    <div>Wir sind Teil von Cloud made in Germany</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Element>

            <Element name="patreon" className="section">
                <div className="section-container align-center">
                    <h2 className="h2-styles">Unterstützen Sie uns auf Patreon</h2>
                    <h3 className="h3-styles">meal-me ist kostenlos, freut sich aber über Spenden</h3>
                    <div className="inner-container">Wir bieten unseren Service für Gastronomen komplett kostenlos an. Dennnoch sind wir auf Ihre finanzielle Unterstützung angewiesen, um Kosten für Server, Personal und Weiterentwicklung tragen zu können.</div>
                    <a href="https://www.patreon.com/mealme" target="_blank" rel="noopener noreferrer" className="button button-secondary border-highlight full-width inner-container gap-top">
                        Unterstützen Sie uns mit Ihrer Spende
                        <img className="image-patreon" src={patreonWordImage} alt="patreon word logo" />
                    </a>
                </div>
            </Element>

            <footer className="section section-inverted align-center">
                <div className="section-container">
                    <div><img src={logoFont} alt="Logo mit Schrift" className="logo-footer" /></div>
                    <div className="disabled">Copyright 2021 alle Rechte bei meal-me UG</div>
                    <nav className="footer-nav">
                        <RouterLink to={ROUTES.SECURITY} className="button button-secondary disable-hover">Datenschutz</RouterLink>
                        <button onClick={() => scroll.scrollToTop()} className="button button-secondary disable-hover">Cookie-Einstellungen</button>
                        <RouterLink to={ROUTES.TERMS} className="button button-secondary disable-hover">AGB</RouterLink>
                        <RouterLink to={ROUTES.CONTACT} className="button button-secondary disable-hover">Kontakt</RouterLink>
                    </nav>
                </div>
            </footer>
        </div>
    );
}


const useStyles = makeStyles({
    popover: {
    },
    paper: {
        position: 'fixed',
        top: 0,
        right: 0,
        padding: '24px',
        width: '432px',
        height: '100%',
        backgroundColor: '#f5f7f8',
    }
});
export default LandingPage;