import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { PasswordForgetLink } from "./passwordForget";
import fIcon from '../../assets/images/f-icon.svg';
import gIcon from '../../assets/images/g-icon.svg';
import TextField from "@material-ui/core/TextField";
import Navigation from "../Navigation/mainNavigation";
import {PersonOutlineOutlined} from "@material-ui/icons";

const SignInPage = () => (
    <div className="app">
        <Navigation />

        <div className="container">
            <h1>Login</h1>
            <SignInSocial />
            <div className="separator gap-top" />
            <SignInForm />
            <PasswordForgetLink />
        </div>
    </div>
);

const SignInPopover = ({changePopoverType}) => {
    return (
        <>
            <h2 className="h4-styles">Anmelden</h2>
            <div>oder <span className="inline-link" onClick={() => changePopoverType('signUp')}>Konto erstellen</span></div>
            <div className="form-group gap-top">
                <PersonOutlineOutlined color="primary" className="section-icon" />
                <h2 className="h4-styles">Persönliche Daten</h2>
                <SignInForm />
                <PasswordForgetLink />
            </div>
            <div className="form-group">
                <div className="separator gap-bottom" />
                <SignInSocial />
            </div>
        </>
    )
}

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email, password } = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then((authUser) => {
                let targetRoute = ROUTES.DASHBOARD_COMPANY;
                this.props.firebase.getUserCollection().doc(authUser.user.uid).get().then(function(doc) {
                    let currentUser = doc.data()
                    targetRoute = currentUser.companyOwner ? ROUTES.DASHBOARD_COMPANY : ROUTES.OVERVIEW;
                });
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(targetRoute);
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <form onSubmit={this.onSubmit} className="form">
                { error && <div className="error-message gap-bottom">{error.message}</div> }
                <div className="field-padding">
                    <TextField
                        name="email"
                        label="E-mail Adresse"
                        value={email}
                        onChange={this.onChange}
                        type="text"
                        autoComplete="username email"
                        variant="outlined"
                    />
                </div>
                <div className="field-padding">
                    <TextField
                        required
                        label="Passwort"
                        name="password"
                        value={password}
                        onChange={this.onChange}
                        type="password"
                        autoComplete="password"
                        variant="outlined"
                    />
                </div>
                <button
                    className="button button-primary full-width gap-top"
                    disabled={isInvalid}
                    type="submit"
                >
                    Anmelden
                </button>
            </form>
        );
    }
}

class SignInSocialBase extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    onClickGoogle = event => {
        event.preventDefault();
        this.props.firebase
            .doSignInWithGoogle()
            .then(authUser => {
                this.props.firebase
                    .getUserCollection()
                    .doc(authUser.user.uid)
                    .set({
                        id: authUser.user.uid,
                        displayName: authUser.user.displayName,
                        email: authUser.user.email,
                        photoUrl: authUser.user.photoURL,
                        socialLogin: true,
                        companyOwner: false,
                    });
            })
            .then(() => {
                this.setState({ error: null });
                this.props.history.push(ROUTES.OVERVIEW);
            })
            .catch(error => {
                this.setState({ error });
            });
    };

    onClickFacebook = event => {
        this.props.firebase
            .doSignInWithFacebook()
            .then(authUser => {
                // add the user to the database
                this.props.firebase
                    .getUserCollection()
                    .doc(authUser.user.uid)
                    .set({
                        id: authUser.user.uid,
                        displayName: authUser.user.displayName,
                        email: authUser.user.email,
                        photoUrl: authUser.user.photoURL,
                        socialLogin: true,
                        companyOwner: false,
                    });
            })
            .then(() => {
                // set local state
                this.setState({ error: null });
                this.props.history.push(ROUTES.OVERVIEW);
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };

    render() {
        const { error } = this.state;

        return (
            <>
                {error && <div className="error-message gap-bottom">{error.message}</div>}
                <button className="button button-social" onClick={this.onClickGoogle}>
                    <img src={gIcon} className="social-icon" alt="login google plus social" />
                </button>
                <button className="button button-social" onClick={this.onClickFacebook}>
                    <img src={fIcon} className="social-icon" alt="login facebook social" />
                </button>
            </>
        );
    }
}

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

const SignInSocial = compose(
    withRouter,
    withFirebase,
)(SignInSocialBase);

export default SignInPage;

export { SignInPopover, SignInForm, SignInSocial };