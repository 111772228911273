import React, {useEffect, useState} from "react";
import moment from 'moment';
import 'moment/locale/de';
import FoodOrderModal from "./foodOrderModal";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const FoodOrderSettings = ({ firebase, foodOrder, foodOrderList, onStopOrder }) => {
    const [active, setActive] = useState(false);
    const [timeLeft, setTimeLeft] = useState('00:00:00')
    let currentFoodOrder = foodOrder ? foodOrder : foodOrderList[0];
    let deadline = moment.unix(currentFoodOrder.deadline.seconds);
    let currentTime = moment();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let isActive = currentFoodOrder.active && deadline.isAfter(currentTime);
        setActive(isActive);

        if (isActive) {
            const timer = setInterval(() => {
                setTimeLeft(calculateTimeLeft());
            }, 1000);

            // Clear timeout if the component is unmounted
            return () => clearTimeout(timer);
        } else {
            setTimeLeft('00:00:00');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [foodOrder, foodOrderList]);

    const toggleConfirm = () => {
        setOpen(!open);
    }

    const calculateTimeLeft = () => {
        let timeLeft = '00:00:00';
        let diff = deadline - new Date();
        if (diff > 0) {
            let hours = Math.floor(diff / (1000 * 60 * 60) % 24).toString().padStart(2, '0');
            let minutes = Math.floor(diff / (1000 * 60) % 60).toString().padStart(2, '0');
            let seconds = Math.floor(diff / (1000) % 60).toString().padStart(2, '0');
            timeLeft = `${hours}:${minutes}:${seconds}`;
        }

        return timeLeft;
    };

    return (
        <>
            <h2>{`${deadline.format('dddd D. MMM')}`}</h2>
            {active ? (
                <>
                    <div className="small-font">{`Laufende Abstimmung endet um ${deadline.format('LT')} Uhr`}</div>
                    <div className="timer active">{timeLeft}</div>
                    <button
                        className="button button-secondary"
                        type="button"
                        onClick={toggleConfirm}
                    >
                        Abstimmung beenden
                    </button>
                    <Dialog
                        open={open}
                        onClose={toggleConfirm}
                        aria-labelledby="stop-order-title"
                        aria-describedby="stop-order-description"
                    >
                        <DialogTitle id="stop-order-title">{`Die Bestellung bei "${foodOrder.restaurant}" beenden`}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="stop-order-description">
                                Wollen Sie die Bestellung bei {foodOrder.restaurant} wirklich beenden?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <div onClick={toggleConfirm} className="button button-secondary">
                                Abbrechen
                            </div>
                            <div onClick={onStopOrder} className="button button-primary" autoFocus>
                                Beenden
                            </div>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <>
                    <div className="small-font">Abstimmung beendet</div>
                    <div className="timer">{timeLeft}</div>
                    <FoodOrderModal
                        firebase={firebase}
                        type={"renew"}
                    />
                </>
            ) }
            <div className="gap-top separator"></div>
            <div className="gap-top small-font">Hier wird bestellt</div>
            <h3 className="restaurant-name">{currentFoodOrder.restaurant}</h3>
            {currentFoodOrder.url ? (
                <a
                    href={currentFoodOrder.url}
                    className="button button-secondary border-highlight"
                    target="_blank"
                    rel="noopener noreferrer">
                    Speisekarte
                </a>
            ) : (
                <></>
            )}
        </>
    );
};

export default FoodOrderSettings;