import React, { useEffect } from 'react';

import { setUserAction } from "../../reducers/user";
import SignInPage from "../Auth/signIn";
import { useDispatch } from "react-redux";
import { withFirebase } from '../Firebase';
import * as ROUTES from "../../constants/routes";
import { useHistory } from "react-router-dom";
import Navigation from "../Navigation/mainNavigation";

const Home = ({ firebase }) => {
    const dispatch = useDispatch();
    const setUser = (user) => dispatch(setUserAction(user));
    let history = useHistory();

    useEffect(() => {
        firebase.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                firebase.getUserCollection().doc(authUser.uid).get().then(function(doc) {
                    setUser(doc.data());
                    history.push(ROUTES.OVERVIEW);
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="app">
            <Navigation />
            <SignInPage />
        </div>
    );
};

export default withFirebase(Home);