import React, { Component } from 'react';

import { withFirebase } from '../Firebase';
import Navigation from "../Navigation/mainNavigation";

const PasswordChangePage = () => {
    return (
        <div className="app">
            <Navigation />
            <PasswordChangeForm />
        </div>
    )
}

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    error: null,
    message: '',
    user: {
        id: '',
        displayName: '',
        email: '',
        photoUrl: '',
        socialLogin: '',
    }
};

class PasswordChangeFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { passwordOne } = this.state;

        this.props.firebase
            .doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    componentDidMount() {
        this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.props.firebase.getUserCollection().doc(authUser.uid).get().then(function(doc) {
                    this.setState({
                        user: doc.data(),
                    });
                }.bind(this));
            } else {
                this.setState({
                    INITIAL_STATE,
                });
            }
        });
    }

    render() {
        const { passwordOne, passwordTwo, error, user } = this.state;
        const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

        return (
            <>
                {user.socialLogin ? (
                        <div className="container gap-top text-block">Sie haben sich über einen Social Media Kanal eingeloggt und können hier deshalb nicht ihr Password ändern. Wechseln Sie bitte auf die entsprechende Plattform und ändern es dort um.</div>
                ) : (
                    <form onSubmit={this.onSubmit} className="form gap-top container">
                        {error && <div className="error-message">{error.message}</div>}
                        <div className="field">
                            <label htmlFor="passwordOne">Passwort eingeben*</label>
                            <input
                                name="passwordOne"
                                value={passwordOne}
                                onChange={this.onChange}
                                type="password"
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="passwordTwo">Passwort bestätigen*</label>
                            <input
                                name="passwordTwo"
                                value={passwordTwo}
                                onChange={this.onChange}
                                type="password"
                            />
                        </div>
                        <div className="button-group">
                            <button disabled={isInvalid} type="submit" className="button button-primary gap-top">
                                Passwort ändern
                            </button>
                        </div>
                    </form>
                )}
            </>
        );
    }
}

export default PasswordChangePage;

const PasswordChangeForm = withFirebase(PasswordChangeFormBase);

export { PasswordChangeForm };