import React, {useEffect, useState} from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import {withFirebase} from "../Firebase";
import * as ROUTES from '../../constants/routes';
import { setUserAction } from '../../reducers/user';
import logo from '../../assets/images/logo.png'
import Popover from "@material-ui/core/Popover";
import defaultAvatar from '../../assets/images/default_avatar.png';
import EditIcon from '@material-ui/icons/Edit';

const Navigation = ({firebase}) => {
    const user = useSelector((state) => state.userReducer.currentUser);
    const dispatch = useDispatch();
    const setUser = (user) => dispatch(setUserAction(user));
    let history = useHistory();
    let location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        firebase.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                firebase.getUserCollection().doc(authUser.uid).get().then(function(doc) {
                    setUser(doc.data());
                });
            } else if (!authUser && ROUTES.PROTECTED_ROUTES.includes(location.pathname)) {
                history.push(ROUTES.UNAUTHORIZED);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = () => {
        setAnchorEl(null);
        firebase
            .doSignOut()
            .then(() => {
                setUser(null);
                history.push(ROUTES.LANDING);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const LinkLogo = () => {
        return (
            <Link to={user ? ROUTES.OVERVIEW : ROUTES.SIGN_IN } className="link-logo">
                <img src={logo} alt="logo" className="logo" />
            </Link>
        );
    };

    const ProfileHeader = () => {
        return (
            <div className="button-group">
                <div className="button-image" aria-describedby={id} onClick={handleClick}>
                    <img src={user.photoUrl ? user.photoUrl : defaultAvatar} className="profile-image" alt="profile" />
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div className="popover">
                        <div className="link-logo" onClick={() => history.push(ROUTES.ACCOUNT)}>
                            <img src={user.photoUrl ? user.photoUrl : defaultAvatar} className="profile-image" alt="profile" />
                            <div className="link-logo-overlay"><EditIcon fontSize="large" /></div>
                        </div>
                        <div className="user-name">{user.displayName}</div>
                        <div className="email-address">{user.email}</div>
                        <button
                            className="button register-button button-secondary border-highlight"
                            onClick={() => logout()}
                        >
                            Logout
                        </button>
                    </div>
                </Popover>
            </div>
        );
    };

    const RegisterButton = () => {
        return (
            <NavLink
                className="button register-button button-secondary border-highlight"
                to={ROUTES.SIGN_UP}
            >
                Registrieren
            </NavLink>
        );
    };

    const LoginButton = () => {
        return (
            <NavLink
                className="button register-button button-secondary border-highlight"
                to={ROUTES.SIGN_IN}
            >
                Einloggen
            </NavLink>
        );
    };

    return (
        <>
            {ROUTES.CLEAN_ROUTES.includes(location.pathname) ? (
                <></>
            ) : (
                <header className="app-header">
                    <nav>
                        <LinkLogo />
                        <div className="right-menu">
                            { user
                                ? (
                                    <ProfileHeader />
                                ) : (
                                    location.pathname === ROUTES.SIGN_IN || location.pathname === ROUTES.LANDING
                                    ? (
                                        <RegisterButton />
                                    ) : (
                                        <LoginButton />
                                    )
                                )
                            }
                        </div>
                    </nav>
                </header>
            )}
        </>
    )
};

export default withFirebase(Navigation);