import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import * as ROUTES from "../../constants/routes";
import { useHistory } from "react-router-dom";
import {Business, PersonOutlineOutlined} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {withFirebase} from "../Firebase";

const SignUpForm = ({firebase, changePopoverType}) => {
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [passwordOne, setPasswordOne] = useState('');
    const [passwordTwo, setPasswordTwo] = useState('');
    const [company, setCompany] = useState('');
    const [accept, setAccept] = useState(false);
    const [error, setError] = useState(null);
    const isInvalid =
        passwordOne !== passwordTwo ||
        passwordOne === '' ||
        email === '' ||
        displayName === '';
    let history = useHistory();

    const acceptLabel = (
        <span>Ich stimme den&nbsp;
            <a
                className="inline-link"
                href="https://www.test.de"
                target="_blank"
                rel="noopener noreferrer"
            >
                AGB für meal-me
            </a>
            &nbsp;zu.
        </span>
    );

    const onSubmit = event => {
        firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                return firebase
                    .getUserCollection()
                    .doc(authUser.user.uid.toString())
                    .set({
                        displayName,
                        email,
                        id: authUser.user.uid,
                        socialLogin: false,
                        companyOwner: true,
                        companyName: company,
                        accept: accept,
                    })
                    .catch(error => {
                        setError(error);
                    });
            })
            .then(() => {
                setDisplayName('');
                setEmail('');
                setPasswordOne('');
                setPasswordTwo('');
                setCompany('');
                setAccept(false);
                history.push(ROUTES.DASHBOARD_COMPANY);
            })
            .catch(error => {
                setError(error);
            });

        event.preventDefault();
    };


    return (
        <>
            <form onSubmit={onSubmit} className="form">
                {error && <div className="button error-message">{error.message}</div>}
                <h2 className="h4-styles">Konto erstellen</h2>
                <div>oder <span className="inline-link" onClick={() => changePopoverType('login')}>anmelden</span></div>
                <div className="form-group gap-top">
                    <PersonOutlineOutlined color="primary" className="section-icon" />
                    <h2 className="h4-styles">Persönliche Daten</h2>
                    <div className="field-padding">
                        <TextField
                            required
                            name="displayName"
                            label="Name"
                            value={displayName}
                            onChange={(event => setDisplayName(event.target.value))}
                            type="text"
                            autoComplete="name"
                            variant="outlined"
                        />
                    </div>
                    <div className="field-padding">
                        <TextField
                            required
                            name="email"
                            label="E-mail"
                            value={email}
                            onChange={(event => setEmail(event.target.value))}
                            type="text"
                            autoComplete="username email"
                            variant="outlined"
                        />
                    </div>
                    <div className="field-padding">
                        <TextField
                            required
                            name="passwordOne"
                            label="Passwort"
                            value={passwordOne}
                            onChange={(event => setPasswordOne(event.target.value))}
                            type="password"
                            autoComplete="new-password"
                            variant="outlined"
                        />
                    </div>
                    <div className="field-padding">
                        <TextField
                            required
                            name="passwordTwo"
                            label="Passwort wiederholen"
                            value={passwordTwo}
                            onChange={(event => setPasswordTwo(event.target.value))}
                            type="password"
                            autoComplete="new-password"
                            variant="outlined"
                        />
                    </div>
                </div>
                <div className="form-group gap-top">
                    <Business color="primary" className="section-icon" />
                    <h2 className="h4-styles">Firma</h2>
                    <div className="field-padding">
                        <TextField
                            required
                            name="company"
                            label="Name Restaurant, Bar, Kneipe, Club"
                            value={company}
                            onChange={(event => setCompany(event.target.value))}
                            type="text"
                            autoComplete="company"
                            variant="outlined"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={accept}
                                    onClick={(event => setAccept(event.target.checked))}
                                    name="accept"
                                    color="primary"
                                    required={true}
                                />
                            }
                            label={<span style={{ fontSize: '14px' }}>{acceptLabel}</span>}
                        />
                    </div>
                    <button
                        className="button button-primary full-width gap-top"
                        disabled={isInvalid}
                        type="submit"
                    >
                        Registrieren
                    </button>
                </div>
            </form>
        </>
    );
}

export default withFirebase(SignUpForm);