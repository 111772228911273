const INITIAL_STATE = {
    users: null,
};

export const setUserAction = (user) => ({
    type: 'SET_USER',
    payload: user,
});

const applySetUsers = (state, action) => ({
    ...state,
    users: action.users,
});

const applySetUser = (state, action) => ({
    ...state,
    users: {
        ...state.users,
        [action.uid]: action.user,
    },
});

function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'USERS_SET': {
            return applySetUsers(state, action);
        }
        case 'USER_SET': {
            return applySetUser(state, action);
        }
        case 'SET_USER': {
            return {
                currentUser: action.payload
            };
        }
        default:
            return state;
    }
}

export default userReducer;