import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import TextField from "@material-ui/core/TextField";
import Navigation from "../Navigation/mainNavigation";

const SignUpPage = () => (
    <div className="app">
        <Navigation />

        <div className="container">
            <h1>Registrierung</h1>
            <SignUpForm />
        </div>
    </div>
);

const INITIAL_STATE = {
    displayName: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
};

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...INITIAL_STATE,
        };
    }

    onSubmit = event => {
        const { displayName, email, passwordOne } = this.state;

        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                return this.props.firebase
                    .getUserCollection()
                    .doc(authUser.user.uid.toString())
                    .set({
                        displayName,
                        email,
                        id: authUser.user.uid,
                        socialLogin: false,
                    });
            })
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {
            displayName,
            email,
            passwordOne,
            passwordTwo,
            error,
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            displayName === '';

        return (
            <form onSubmit={this.onSubmit} className="form">
                { error && <div className="button error-message">{error.message}</div>}
                <div className="field">
                    <TextField
                        required
                        name="displayName"
                        label="Name"
                        value={displayName}
                        onChange={this.onChange}
                        type="text"
                        autoComplete="name"
                        variant="outlined"
                    />
                </div>
                <div className="field">
                    <TextField
                        required
                        name="email"
                        label="E-mail"
                        value={email}
                        onChange={this.onChange}
                        type="text"
                        autoComplete="username email"
                        variant="outlined"
                    />
                </div>
                <div className="field">
                    <TextField
                        required
                        name="passwordOne"
                        label="Passwort"
                        value={passwordOne}
                        onChange={this.onChange}
                        type="password"
                        autoComplete="new-password"
                        variant="outlined"
                    />
                </div>
                <div className="field">
                    <TextField
                        required
                        name="passwordTwo"
                        label="Passwort wiederholen"
                        value={passwordTwo}
                        onChange={this.onChange}
                        type="password"
                        autoComplete="new-password"
                        variant="outlined"
                    />
                </div>
                <button
                    className="button button-primary full-width gap-top"
                    disabled={isInvalid}
                    type="submit"
                >
                    Registrieren
                </button>
            </form>
        );
    }
}

const SignUpLink = () => (
    <div className="ui message">
        Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
    </div>
);

const SignUpForm = compose(
    withRouter,
    withFirebase,
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink };