import React from "react";
import RestaurantOverviewPage from "../Restaurant/overview";
import Navigation from "../Navigation/mainNavigation";

const DashboardPage = () => {
    return (
        <div className="app">
            <Navigation />
            <RestaurantOverviewPage />
        </div>
    )
};

export default DashboardPage;
