import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import SignUpPage from '../Auth/signUp';
import SignInPage from '../Auth/signIn';
import PasswordForgetPage from '../Auth/passwordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import PasswordChangePage from "../Auth/passwordChange";
import HistoryPage from '../History/history';
import ErrorPage404 from "../Error/error404";
import ErrorPage401 from "../Error/error401";

import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import FoodOrderPage from "../FoodOrder/foodOrderPage";
import LandingPage from "../Landing/landing";
import SecurityPage from "../Information/security";
import TermsPage from "../Information/terms";
import ContactPage from "../Information/contact";
import DashboardPage from "../Company/dashboard";
import RegistrationPage from "../Registration/registration";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authUser: null,
        };

        this.firebase = props.firebase;
    }

    componentDidMount() {
        this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
            authUser
                ? this.setState({authUser})
                : this.setState({authUser: null});
        })
    }

    componentWillUnmount() {
        this.listener();
    }

    render() {
        const mealMeTheme = createMuiTheme({
            palette: {
                primary: {
                    main: '#d77a61',
                },
                secondary: {
                    main: '#d77a61',
                },
            },
        });

        return (
            <Router>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ThemeProvider theme={mealMeTheme}>
                        <Switch>
                            <Route exact path={ROUTES.LANDING} component={LandingPage} />
                            <Route exact path={ROUTES.OVERVIEW} component={FoodOrderPage} />
                            <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
                            <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
                            <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                            <Route exact path={ROUTES.UNAUTHORIZED} component={ErrorPage401} />
                            <Route exact path={ROUTES.SECURITY} component={SecurityPage} />
                            <Route exact path={ROUTES.TERMS} component={TermsPage} />
                            <Route exact path={ROUTES.CONTACT} component={ContactPage} />
                            <Route exact path={ROUTES.DASHBOARD_COMPANY} component={DashboardPage} />

                            {/* ROUTES for logged in users */}
                            <Route exact path={ROUTES.HOME} component={HomePage} />
                            <Route exact path={ROUTES.HISTORY} component={HistoryPage} />
                            <Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
                            <Route exact path={ROUTES.PASSWORD_CHANGE} component={PasswordChangePage} />
                            <Route path={ROUTES.REGISTRATION} component={RegistrationPage} />
                            <Route component={ErrorPage404} />
                        </Switch>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </Router>
        );
    }
}

export default withFirebase(App);