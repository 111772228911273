import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Navigation from "../Navigation/mainNavigation";

const PasswordForgetPage = () => (
    <div className="app">
        <Navigation />

        <div className="container">
            <h1>Neues Passwort anfordern</h1>
            <PasswordForgetForm />
        </div>
    </div>
);

const INITIAL_STATE = {
    email: '',
    error: null,
    message: '',
};

class PasswordForgetFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email } = this.state;

        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.setState({ message: `Ein Link zum Zurücksetzen deines Passworts wurde an ${email} vesendet.` })
            })
            .catch(error => {
                this.setState({ error });
            });

        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, error, message } = this.state;

        const isInvalid = email === '';

        return (
            <>
                {error && <div className="button error-message">{error.message}</div>}
                {message && <div className="button button-success">{message}</div>}
                <form onSubmit={this.onSubmit} className="form">
                    <div className="field">
                        <label htmlFor="email">E-mail*</label>
                        <input
                            name="email"
                            value={email}
                            onChange={this.onChange}
                            type="text"
                        />
                    </div>
                    <button disabled={isInvalid} type="submit" className="button button-primary gap-top full-width">
                        Passwort anfordern
                    </button>
                </form>
            </>
        );
    }
}

const PasswordForgetLink = () => (
    <>
        <Link
            className="button register-button button-secondary full-width gap-top"
            to={ROUTES.PASSWORD_FORGET}
        >
            Passwort vergessen?
        </Link>
    </>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };