import React, {useState} from "react";
import Modal from "@material-ui/core/Modal";
import uuid from "uuid/v4";
import { TimePicker } from "@material-ui/pickers";
import emptyStateImage from '../../assets/images/empty_state.png';

const FoodOrderModal = ({ firebase, type }) => {
    const [open, setOpen] = useState(false);
    const [deadline, setDeadline] = useState(new Date());
    const [restaurant, setRestaurant] = useState('');
    const [url, setUrl] = useState('');
    const [error, setError] = useState('');
    const isInvalid = deadline < new Date() || deadline > (new Date()).setHours(23, 59, 59) || restaurant === ''

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const onOrderSubmit = event => {
        event.preventDefault();

        let thisMorning = new Date();
        thisMorning.setHours(0, 0, 0,0);

        let foodOrder = {
            id: uuid(),
            deadline: new Date(deadline),
            createdAt: new Date(),
            restaurant: restaurant,
            date: thisMorning,
            url: url,
            orderItems: [],
            active: true,
        };

        firebase
            .getFoodOrderCollection()
            .doc(foodOrder.id.toString())
            .set(foodOrder)
            .then(() => {
                setRestaurant('');
                setDeadline(new Date());
                setUrl('');
                handleClose();
            })
            .catch(() => setError('Bei der Bestellung ist etwas schief gegangen.'));

    };

    const body = (
        <div className="modal">
            <h2 id="simple-modal-title">Abstimmung hinzufügen</h2>
            {error && <div className="error-message">{error}</div>}
            <form onSubmit={onOrderSubmit}>
                <div className="field">
                    <label htmlFor="deadline">Deadline*</label>
                    <TimePicker
                        placeholder="08:00 AM"
                        mask="__:__"
                        value={deadline}
                        ampm={false}
                        onChange={setDeadline}
                        InputProps={{
                            disableUnderline: true
                        }}
                        minDate={new Date()}
                        maxDate={(new Date()).setHours(23,59,59)}
                    />
                </div>
                <div className="field">
                    <h3>Wo wird bestellt?</h3>
                </div>
                <div className="field">
                    <label htmlFor="restaurant">Name Restaurant*</label>
                    <input
                        type="text"
                        name="restaurant"
                        value={restaurant}
                        onChange={(event => setRestaurant(event.target.value))}
                    />
                </div>
                <div className="field">
                    <label htmlFor="url">Link zur Speisekarte</label>
                    <input
                        type="text"
                        name="url"
                        value={url}
                        onChange={(event => setUrl(event.target.value))}
                    />
                </div>
                <div className="button-group">
                    <button
                        className="button button-secondary"
                        type="button"
                        onClick={handleClose}
                    >
                        Abbrechen
                    </button>
                    <button
                        className="button button-primary"
                        type="submit"
                        disabled={isInvalid}
                    >
                        Speichern
                    </button>
                </div>
            </form>
        </div>
    );

    return (
        <>
            {type === 'new' ? (
                <>
                    <img src={emptyStateImage} alt="Hunger" className="empty-state-image" />
                    <h2>Du drohst zu verhungern!</h2>
                    <p>Starte eine Abstimmung und sichere das Überleben des Rudels!</p>
                    <button
                        className="button button-primary"
                        type="button"
                        onClick={handleOpen}
                    >
                        Abstimmung starten
                    </button>
                </>
            ) : (
                <button
                    className="button button-secondary"
                    type="button"
                    onClick={handleOpen}
                >
                    Neue Abstimmung starten
                </button>
            )}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </>
    )
};

export default FoodOrderModal;
