import React from "react";
import Navigation from "../Navigation/mainNavigation";
import DummyPage from "../Dummy";

const SecurityPage = () => {
    return (
        <div className="app">
            <Navigation />
            <DummyPage />
        </div>
    )
};

export default SecurityPage;
