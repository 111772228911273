import React from 'react';
import RestaurantItem from "./restaurantItem";

const RestaurantList = ({restaurants}) => {
    return (
        <div className="restaurant-list">
            {restaurants.map(restaurantItem => (
                <RestaurantItem
                    key={restaurantItem.id}
                    restaurant={restaurantItem}
                />
            ))}
        </div>
    )
};

export default RestaurantList;