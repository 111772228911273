import React from "react";
import { useHistory } from "react-router-dom";
import emptyStateImage from "../../assets/images/empty_state.png";
import * as ROUTES from "../../constants/routes";
import Navigation from "../Navigation/mainNavigation";

const Error404 = () => {
    let history = useHistory();

    return (
        <div className="app">
            <Navigation />

            <div className="container-full error-page">
                <h1 className="error-page-headline"><span>4</span> <img src={emptyStateImage} alt="Hunger" className="empty-state-image" /> <span>4</span></h1>
                <p>Da bist du wohl irgendwo falsch abgebogen</p>
                <p>Versuchs doch auf der Startseite nochmal</p>
                <button
                    className={`button button-secondary border-highlight`}
                    onClick={() => history.push(ROUTES.LANDING)}
                >
                    Startseite
                </button>
            </div>
        </div>
    )
};

export default Error404;