import React, { useState } from 'react';
import Modal from "@material-ui/core/Modal";
import uuid from "uuid/v4";
import { useSelector } from "react-redux";
import Switch from "@material-ui/core/Switch";

const OrderModal = ({ firebase, foodOrder }) => {
    const user = useSelector((state) => state.userReducer.currentUser);
    const [open, setOpen] = useState(false);
    const [meal, setMeal] = useState('');
    const [price, setPrice] = useState('0,00');
    const [displayName, setDisplayName] = useState('');
    const [guest, setGuest] = useState(false);
    const isInvalid = meal === '' || (guest === true && displayName === '') || (price !== '' && price.match("^[0-9,;]+$") === null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMeal('');
        setDisplayName('');
        setPrice('0,00');
    };

    const onOrderSubmit = event => {
        event.preventDefault();
        if (meal.trim() === '') return;

        let orderItem = {
            id: uuid(),
            meal: meal,
            user_id: user.id,
            createdAt: new Date(),
            displayName: guest ? displayName : user.displayName,
            isActive: true,
            foodOrder_id: foodOrder.id,
            price: parseFloat(price.replace(',','.').replace(' ','')).toFixed(2),
        };

        foodOrder.orderItems.push(orderItem);
        firebase
            .getFoodOrderCollection()
            .doc(foodOrder.id)
            .update(foodOrder);

        setMeal('');
        setDisplayName('');
        handleClose();
    };

    const body = (
        <div className="modal">
            <h2 id="simple-modal-title">Auswahl treffen</h2>
            <p id="simple-modal-description">
                Bestellt wird bei:
            </p>
            <h3>LunchBox</h3>
            {foodOrder.url ? (
                <a
                    href={foodOrder.url}
                    className="button button-secondary border-highlight"
                    target="_blank"
                    rel="noopener noreferrer">
                    Speisekarte
                </a>
            ) : (
                <></>
            )}
            <form onSubmit={onOrderSubmit}>
                <div className="field">
                    <Switch
                        checked={guest}
                        onChange={(event) => setGuest(event.target.checked)}
                        color="primary"
                        name="check"
                    />
                    <label htmlFor="check">Bestellung für Gast?</label>
                </div>
                {guest ? (
                    <div className="field">
                        <label htmlFor="displayName">Bestellung für</label>
                        <input
                            type="text"
                            name="displayName"
                            value={displayName}
                            onChange={(event => setDisplayName(event.target.value))}
                        />
                    </div>
                ) : ''}
                <div className="field">
                    <label htmlFor="meal">Gericht eintragen*</label>
                    <input
                        type="text"
                        name="meal"
                        value={meal}
                        onChange={(event => setMeal(event.target.value))}
                        autoFocus={true}
                    />
                </div>
                <div className="field">
                    <label htmlFor="price">Preis</label>
                    <input
                        type="text"
                        name="price"
                        value={price}
                        onChange={(event => setPrice(event.target.value))}
                    />
                </div>
                <div className="button-group">
                    <button
                        className="button button-secondary"
                        type="button"
                        onClick={handleClose}
                    >
                        Abbrechen
                    </button>
                    <button
                        className="button button-primary"
                        type="submit"
                        disabled={isInvalid}
                    >
                        Speichern
                    </button>
                </div>
            </form>
        </div>
    );

    return (
        <>
            <button
                className="button button-primary"
                type="button"
                onClick={handleOpen}
            >
                Ich möchte bestellen
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </>
    )
};

export default OrderModal;