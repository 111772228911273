import React, {useState} from 'react';
import Icon from "@material-ui/core/Icon";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const OrderItem = ({orderItem, onRemoveItem}) => {
    const [open, setOpen] = useState(false);

    const toggleConfirm = () => {
        setOpen(!open);
    }

   return (
       <div className="order-item">
           <div className="order-options">
               <div className="order-name">{orderItem.displayName}</div>
               <div className="order-meal">{orderItem.meal}</div>
           </div>
           {onRemoveItem ? (
               <>
                   <div className="order-delete" onClick={toggleConfirm}><Icon>delete_outline</Icon></div>
                   <Dialog
                       open={open}
                       onClose={toggleConfirm}
                       aria-labelledby="alert-dialog-title"
                       aria-describedby="alert-dialog-description"
                   >
                       <DialogTitle id="alert-dialog-title">{`Das Gericht "${orderItem.meal}" löschen`}</DialogTitle>
                       <DialogContent>
                           <DialogContentText id="alert-dialog-description">
                               Wollen Sie den Eintrag von {orderItem.displayName} wirklich löschen?
                           </DialogContentText>
                       </DialogContent>
                       <DialogActions>
                           <div onClick={toggleConfirm} className="button button-secondary">
                               Abbrechen
                           </div>
                           <div onClick={onRemoveItem.bind(null, orderItem)} className="button button-primary" autoFocus>
                               Löschen
                           </div>
                       </DialogActions>
                   </Dialog>
               </>
           ) : (
               <></>
           )}
       </div>
   )
};

export default OrderItem;