import React, { useState } from 'react';
import Modal from "@material-ui/core/Modal";
import uuid from "uuid/v4";
import { useSelector } from "react-redux";
import Switch from "@material-ui/core/Switch";
import slugify from "react-slugify";
import TextField from "@material-ui/core/TextField";

const RestaurantModal = ({ firebase }) => {
    const user = useSelector((state) => state.userReducer.currentUser);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(null);
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [googleRating, setGoogleRating] = useState(false);
    const [googleAddress, setGoogleAddress] = useState('');
    const [slug, setSlug] = useState('');
    const isInvalid = (name === '' || (googleRating === true && googleAddress === ''));


    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setStreet('');
        setStreetNumber('');
        setZip('');
        setCity('');
        setGoogleRating(false);
        setGoogleAddress('');
    };

    const updateSlug = (event) => {
        if (event.target.name === 'name') {
            setName(event.target.value);
        }

        setSlug(slugify(event.target.value));
        //TODO validate Slug is unique
    }

    const onOrderSubmit = event => {
        event.preventDefault();
        //TODO validate Slug is unique and valid

        let restaurantItem = {
            id: uuid(),
            id_owner: user.id,
            name,
            street,
            streetNumber,
            slug,
            zip,
            city,
            googleRating,
            googleAddress,
            active: true,
            createdAt: new Date(),
        };

        firebase
            .getRestaurantCollection()
            .doc(restaurantItem.id.toString())
            .set(restaurantItem)
            .then(() => {
                setName('');
                setStreet('');
                setStreetNumber('');
                setZip('');
                setCity('');
                setGoogleRating(false);
                setGoogleAddress('');
                setSlug('');
                handleClose();
            })
            .catch(() => setError('Beim Anlegen des Restaurants ist etwas schief gegangen.'));
    };

    const body = (
        <div className="modal">
            <h2 id="simple-modal-title">Restaurant hinzufügen</h2>
            {error && <div className="button error-message">{error}</div>}
            <form onSubmit={onOrderSubmit}>
                <TextField
                    name="name"
                    label="Name"
                    value={name}
                    onChange={(event => updateSlug(event))}
                    type="text"
                    variant="outlined"
                    required={true}
                    autoFocus={true}
                />
                <TextField
                    name="slug"
                    label="Url für Kunden zur Registrierung"
                    value={slug}
                    onChange={(event => updateSlug(event))}
                    type="text"
                    variant="outlined"
                    required={true}
                />
                <TextField
                    name="street"
                    label="Straße"
                    value={street}
                    onChange={(event => setStreet(event.target.value))}
                    type="text"
                    variant="outlined"
                />
                <TextField
                    name="streetNumber"
                    label="Hausnummer"
                    value={streetNumber}
                    onChange={(event => setStreetNumber(event.target.value))}
                    type="text"
                    variant="outlined"
                />
                <TextField
                    name="zip"
                    label="Postleitzahl"
                    value={zip}
                    onChange={(event => setZip(event.target.value))}
                    type="text"
                    variant="outlined"
                />
                <TextField
                    name="city"
                    label="Stadt"
                    value={city}
                    onChange={(event => setCity(event.target.value))}
                    type="text"
                    variant="outlined"
                />
                <TextField
                    name="city"
                    label="Stadt"
                    value={city}
                    onChange={(event => setCity(event.target.value))}
                    type="text"
                    variant="outlined"
                />
                <Switch
                    checked={googleRating}
                    onChange={(event) => setGoogleRating(event.target.checked)}
                    color="primary"
                    name="googleRating"
                />
                <label htmlFor="googleRating">Bestellung für Gast?</label>
                <TextField
                    name="googleAddress"
                    label="Link zu Ihren Google-Bewertungen"
                    value={googleAddress}
                    onChange={(event => setGoogleAddress(event.target.value))}
                    type="text"
                    variant="outlined"
                />
                <a
                    href="https://something.com"
                    className="inline-link"
                    target="_blank"
                    rel="noopener noreferrer">
                    So richten Sie Ihr Google Profil ein
                </a>
                <div className="button-group">
                    <button
                        className="button button-secondary"
                        type="button"
                        onClick={handleClose}
                    >
                        Abbrechen
                    </button>
                    <button
                        className="button button-primary"
                        type="submit"
                        disabled={isInvalid}
                    >
                        Speichern
                    </button>
                </div>
            </form>
        </div>
    );

    return (
        <>
            <button
                className="button button-primary"
                type="button"
                onClick={handleOpen}
            >
                Restaurant hinzufügen
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </>
    )
};

export default RestaurantModal;
