import React, { useEffect, useState } from "react";
import { withFirebase } from "../Firebase";
import CircularProgress from "@material-ui/core/CircularProgress";
import OrderList from "../OrderItem/orderList";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { de } from 'date-fns/locale'
import DateFnsUtils from "@date-io/date-fns";
import Navigation from "../Navigation/mainNavigation";
import SubNavigation from "../Navigation/subNavigation";

const History = ({ firebase }) => {
    const [isLoading, setIsLoading] = useState(true);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    const [currentDate, setCurrentDate] = useState(today);
    const [foodOrderList, setFoodOrderList] = useState([]);

    useEffect(() => {
        let isMounted = true; // note this flag denote mount status
        setIsLoading(true);

        firebase
            .getFoodOrderCollection()
            .orderBy("createdAt", "desc")
            .where("date", "==", currentDate)
            .onSnapshot(collection => {
                if (isMounted) {
                    let foodOrderList = collection.docs.map(function (snapshot) {
                        return snapshot.data();
                    });

                    setFoodOrderList(foodOrderList);
                    setIsLoading(false);
                }
            });
        return () => { isMounted = false };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDate, setCurrentDate]);


    return (
        <div className="app">
            <Navigation />
            <SubNavigation />

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={de}>
                <>
                    <div className="container-full">
                        <div className="container">
                            <DatePicker
                                autoOk
                                variant="static"
                                openTo="date"
                                value={currentDate}
                                onChange={setCurrentDate}
                                disableToolbar="true"
                            />
                        </div>
                        <div className="container">
                            { isLoading ? (
                                <CircularProgress />
                            ) : (
                                foodOrderList.length > 0 ? (
                                    foodOrderList.map((foodOrder, index) => (
                                        <OrderList
                                            foodOrder={foodOrder}
                                            onRemoveItem={null}
                                            key={foodOrder.id}
                                            collapse={true}
                                            index={index}
                                        />)
                                    )
                                ) : (
                                    <div>Keine Einträge gefunden.</div>
                                )
                            )}
                        </div>
                    </div>
                </>
            </MuiPickersUtilsProvider>
        </div>
    )
};

export default withFirebase(History);