import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { Button } from "semantic-ui-react";

import { withFirebase } from "../Firebase";
import Navigation from "../Navigation/mainNavigation";
import SubNavigation from "../Navigation/subNavigation";

const INITIAL_STATE = {
    currentDisplayName: '',
    currentEmail: '',
    message: '',
    user: {
        id: '',
        displayName: '',
        email: '',
        photoUrl: '',
        socialLogin: '',
    }
};

class Account extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...INITIAL_STATE,
        };
    }


    onChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onSubmit = event => {
        event.preventDefault();
        this.props.firebase
            .getUserCollection()
            .doc(this.state.user.id.toString())
            .set({
                id: this.state.user.id,
                displayName: this.state.currentDisplayName,
                email: this.state.currentEmail,
                photoUrl: this.state.user.photoUrl,
                socialLogin: this.state.socialLogin,
            });

        //TODO dispatch to reducer

        this.setState({
            message: "Dein Konto wurde erfolgreich geändert.",
            user: {
                id: this.state.user.id,
                displayName: this.state.currentDisplayName,
                email: this.state.currentEmail,
                photoUrl: this.state.user.photoUrl,
            }
        });
    };

    componentDidMount() {
        this.listener = this.props.firebase.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.props.firebase.getUserCollection().doc(authUser.uid).get().then(function(doc) {
                    this.setState({
                        currentDisplayName: doc.data().displayName,
                        currentEmail: doc.data().email,
                        user: {
                            id: doc.data().id,
                            displayName: doc.data().displayName,
                            email: doc.data().email,
                            photoUrl: doc.data().photoUrl,
                            socialLogin: doc.data().socialLogin,
                        }
                    });
                }.bind(this));
            } else {
                this.setState({
                    INITIAL_STATE,
                });
            }
        });
    }

    render() {
        const { currentDisplayName, currentEmail, message, user } = this.state;
        const isInvalid = currentDisplayName === '' || currentEmail === '' || (user.displayName === currentDisplayName && user.email === currentEmail);

        return (
            <div className="app">
                <Navigation />
                <SubNavigation />

                <div className="ui container">
                    <h1>Account</h1>

                    {message && <div className="button button-success">{message}</div>}
                    <form className="form" onSubmit={this.onSubmit}>
                        <div className="field">
                            <label>Name</label>
                            <input
                                type="text"
                                name="currentDisplayName"
                                value={currentDisplayName}
                                onChange={this.onChange}
                            />
                        </div>
                        {user.socialLogin ? (
                            <></>
                        ) : (
                            <div className="field">
                                <label>Email</label>
                                <input
                                    type="text"
                                    name="currentEmail"
                                    value={currentEmail}
                                    onChange={this.onChange}
                                />
                            </div>
                        )}
                        <div className="button-group">
                            <Button
                                type="submit"
                                className="button button-primary gap-top"
                                disabled={isInvalid}
                            >
                                Speichern
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        );
    };
}

const AccountPage = compose(
    withRouter,
    withFirebase,
)(Account);

export default withFirebase(AccountPage);