import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as ROUTES from '../../constants/routes';

const SubNavigation = () => {
    let location = useLocation();
    let history = useHistory();
    let subNavigation = null;

    const ProfileSubNavigation = () => {
        return (
            <nav className="gap-top">
                <button
                    className={`button ${location.pathname === ROUTES.ACCOUNT ? 'button-primary' : 'button-secondary'}`}
                    onClick={() => history.push(ROUTES.ACCOUNT)}
                >
                    Benutzerkonto
                </button>
                <button
                    className={`button ${location.pathname === ROUTES.PASSWORD_CHANGE ? 'button-primary' : 'button-secondary'}`}
                    onClick={() => history.push(ROUTES.PASSWORD_CHANGE)}
                >
                    Passwort
                </button>
            </nav>
        );
    };

    const OrderSubNavigation = () => {
        return (
            <nav className="gap-top">
                <button
                    className={`button ${location.pathname === ROUTES.OVERVIEW ? 'button-primary' : 'button-secondary'}`}
                    onClick={() => history.push(ROUTES.OVERVIEW)}
                >
                    Heute
                </button>
                <button
                    className={`button ${location.pathname === ROUTES.HISTORY ? 'button-primary' : 'button-secondary'}`}
                    onClick={() => history.push(ROUTES.HISTORY)}
                >
                    Historie
                </button>
            </nav>
        );
    };

    if (ROUTES.ACCOUNT_PAGE_ROUTES.includes(location.pathname)) {
        subNavigation = <ProfileSubNavigation />
    } else if (ROUTES.ORDER_PAGE_ROUTES.includes(location.pathname)) {
        subNavigation = <OrderSubNavigation />
    }

    return (
        <>
            {subNavigation}
        </>
    )
};

export default SubNavigation;