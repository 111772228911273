import React, { useState } from 'react';
import OrderItem from "./orderItem";
import Collapse from '@material-ui/core/Collapse';
import { KeyboardArrowRight, KeyboardArrowDown } from '@material-ui/icons';
import moment from "moment";

const OrderList = ({foodOrder, onRemoveItem, collapse, index}) => {
    const [open, setOpen] = useState(false);
    let orderItemsArray = [];
    if (foodOrder.orderItems !== undefined) {
        orderItemsArray = Object.values(foodOrder.orderItems);
    }
    let deadline = moment.unix(foodOrder.deadline.seconds);
    let currentTime = moment();
    let isActive = foodOrder.active && deadline.isAfter(currentTime);

    return (
        <>
            {collapse ? (
                <>
                    <div
                        className={`collapsible-header ${index > 0 ? "gap-top" : ""} ${isActive ? "active" : ""}`}
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDown className="icon-collapsible" /> : <KeyboardArrowRight className="icon-collapsible" />}
                        <span className="restaurant-name">{foodOrder.restaurant}</span>
                        <span className="deadline-name">
                            {isActive ? `Endet um ${deadline.format('LT')} Uhr` : `Um ${deadline.format('LT')} Uhr beendet`}
                        </span>
                    </div>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <h3>Teilnehmer ({orderItemsArray.length})</h3>
                        {orderItemsArray.map(orderItem => (
                            <OrderItem
                                orderItem={orderItem}
                                key={orderItem.id}
                                onRemoveItem={onRemoveItem}
                            />
                        ))}
                    </Collapse>
                </>
            ) : (
                <>
                    <h3>Teilnehmer ({orderItemsArray.length})</h3>
                    <div className="separator"></div>
                    {orderItemsArray.map(orderItem => (
                        <OrderItem
                            orderItem={orderItem}
                            key={orderItem.id}
                            onRemoveItem={onRemoveItem}
                        />
                    ))}
                </>
            )}
        </>
    )
};

export default OrderList;