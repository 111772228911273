export const LANDING = '/';
export const OVERVIEW = '/overview';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_CHANGE = '/pw-change';
export const HISTORY = '/history';
export const UNAUTHORIZED = '/unauthorized';
export const SECURITY = '/security';
export const TERMS = '/terms';
export const CONTACT = '/contact';
export const DASHBOARD_COMPANY = '/company';
export const REGISTRATION = '/r/*';

// ROUTE BUNDLES FOR DIFFERENT AREAS
export const PROTECTED_ROUTES = [HISTORY, PASSWORD_CHANGE, ADMIN, ACCOUNT, HOME, OVERVIEW];
export const ADMIN_ROUTES = [];
export const BUSINESS_ROUTES = [];
export const CLEAN_ROUTES = [LANDING];
export const ACCOUNT_PAGE_ROUTES = [ACCOUNT, PASSWORD_CHANGE];
export const ORDER_PAGE_ROUTES = [OVERVIEW, HISTORY];